"use client";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslations } from "next-intl";
import Slider from "react-slick";
import Link from "next/link";
import Image from "next/image";
import { Progress } from "@nextui-org/react";
import {
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
  type RefObject,
} from "react";
import "../../styles/slider.css";

const slideSpeed = 400;
const autoplaySpeed = 6000;

const CustomPaging = forwardRef(function (props: {
  slideIndex: number;
  index: number;
  sliderRef: RefObject<Slider>;
}) {
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (props.slideIndex > props.index) {
      setValue(100);
      return;
    } else if (props.slideIndex < props.index) {
      setValue(0);
      return;
    }

    const interval = setInterval(() => {
      setValue((v) => {
        if (v == 100) {
          // @ts-ignore
          props.sliderRef?.current?.slickGoTo(props.slideIndex + 1);
        }
        return v >= 100 ? 0 : v + 1;
      });
    }, autoplaySpeed / 100);

    return () => clearInterval(interval);
  }, [props.slideIndex]);

  return (
    <Progress
      disableAnimation={
        props.index == props.slideIndex || props.index == props.slideIndex - 1
          ? false
          : true
      }
      classNames={{
        base: `max-w-md`,
        indicator: "bg-white",
      }}
      aria-label="--"
      size="sm"
      value={
        props.slideIndex == props.index
          ? value
          : props.slideIndex > props.index
            ? 100
            : 0
      }
    />
  );
});

function WhatWeDoSection() {
  const t = useTranslations("Index");
  const [slideIndex, setSlideIndex] = useState(0);
  let sliderRef = useRef<Slider>(null);

  const content = useMemo<{ 0: any; 1: any }[]>(() => {
    return [
      {
        0: "what_we_do_section.airport_transfer",
        1: "what_we_do_section.efficient_airport_transfers_with_professional_drivers_for_stress_free_travel",
      },
      {
        0: "what_we_do_section.a_to_b_ride",
        1: "what_we_do_section.a_to_b_ride_offers_stress_free_travel_with_professional_drivers_and_easy_booking",
      },
      {
        0: "what_we_do_section.chauffeur_service",
        1: "what_we_do_section.embark_on_luxury_travel_with_our_chauffeur_service_elevating_your_journey_to_new_heights_of_comfort_and_sophistication",
      },
      {
        0: "what_we_do_section.group_booking",
        1: "what_we_do_section.experience_convenient_group_booking_for_an_exceptional_journey",
      },
    ];
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    speed: slideSpeed,
    autoplaySpeed: autoplaySpeed,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (_: any, next: any) => {
      // console.log(current);
      setSlideIndex(next);
    },
  };

  return (
    <section
      className=" w-full max-w-screen-xl items-center justify-between 
      px-[40px] pt-[91px] pb-[108px] grid grid-cols-1 md:grid-cols-2 
      gap-[34px] md:gap-0 md:px-0 "
    >
      <div className=" md:max-w-[400px] ">
        <p className="h1 mb-[28px]">{t("what_we_do_section.what_we_do")}</p>
        <p className="h5 mt-[24px]">{t("what_we_do_section.desc")}</p>
        <div className="mt-[48px] space-x-[32px]">
          <a href={"/airport-transfer"}>
            <button
              className=" bg-black hover:opacity-80 transition-all
              rounded-[10px] text-white font-bold 
              font-dm_sans text-[12px] md:text-[16px] md:h-[52px] h-[40px] px-[32px]"
            >
              {t("what_we_do_section.learn_more")}
            </button>
          </a>
          <Link
            href={"/group-booking"}
            className=" text-black hover:opacity-80 hover:text-black "
          >
            <button
              className=" transition-all
              rounded-[10px] font-bold underline
              font-dm_sans text-[12px] md:text-[16px] md:h-[52px] h-[40px]"
            >
              {t("what_we_do_section.group_booking")}
            </button>
          </Link>
        </div>
      </div>

      <div className=" w-full lg:h-[650px] h-[450px]">
        <div className=" relative w-full lg:h-[650px] h-[450px] !p-0 rounded-[22.5px] md:rounded-[32px] overflow-hidden">
          <Slider
            ref={sliderRef}
            {...settings}
            className="overflow-hidden h-full w-full !p-0 "
          >
            <div className="h-full w-full outline-none !p-0">
              <Image
                src={"/assets/images/travel.png"}
                alt="travel"
                width={543}
                height={602}
                quality={100}
                className=" object-cover w-full lg:h-[650px] h-[450px]"
              />
            </div>
            <div className="h-full w-full outline-none ">
              <Image
                src={"/assets/images/uber-ride-passenger.jpg"}
                alt="uber-ride-passenger"
                width={543}
                quality={100}
                height={602}
                className=" object-cover w-full lg:h-[650px] h-[450px]"
              />
            </div>
            <div className="h-full w-full outline-none ">
              <Image
                src={"/assets/images/carey.png"}
                alt="carey"
                width={543}
                height={602}
                quality={100}
                className=" object-cover w-full lg:h-[650px] h-[450px]"
              />
            </div>
            <div className="h-full w-full outline-none  ">
              <Image
                src={
                  "/assets/images/z5333588527266_e5b785bfab99e542da6fe4f259cf78ab.png"
                }
                alt="z5333588527266_e5b785bfab99e542da6fe4f259cf78ab"
                width={543}
                height={602}
                quality={100}
                className=" object-cover w-full lg:h-[650px] h-[450px]"
              />
            </div>
          </Slider>
          <div
            className=" absolute w-full h-1/2 inset-x-0 bottom-0 top-50 
             gradient-mask-b-to-t
            flex flex-col justify-end "
          >
            <div
              className=" px-[40px] md:px-[60px] text-white space-y-[10px]
                       md:space-y-[16px] w-full mb-[25px] md:mb-[40px] z-50"
            >
              <p
                className="font-degular-display-semibold md:text-[48px] text-[30px]
                 !leading-none text-left"
              >
                {t(content[slideIndex]?.[0])}
              </p>
              <p
                className={` font-dm_sans text-[12px] md:text-[16px] 
                            !leading-[1.2] tracking-[-3%] text-left 
                            ${slideIndex == 2 ? "" : "max-w-[390px]"}`}
              >
                {t(content[slideIndex]?.[1])}
              </p>
            </div>

            <div
              className="md:mb-[60px] mb-[40px] md:px-[60px] px-[40px] w-full flex flex-row 
                items-center justify-center space-x-[8px]"
            >
              <button
                className="flex-1 z-50"
                onClick={() => sliderRef.current?.slickGoTo(0)}
              >
                <CustomPaging
                  slideIndex={slideIndex}
                  index={0}
                  sliderRef={sliderRef}
                />
              </button>
              <button
                className="flex-1 z-50"
                onClick={() => sliderRef.current?.slickGoTo(1)}
              >
                <CustomPaging
                  slideIndex={slideIndex}
                  index={1}
                  sliderRef={sliderRef}
                />
              </button>
              <button
                className="flex-1 z-50"
                onClick={() => sliderRef.current?.slickGoTo(2)}
              >
                <CustomPaging
                  slideIndex={slideIndex}
                  index={2}
                  sliderRef={sliderRef}
                />
              </button>
              <button
                className="flex-1 z-50"
                onClick={() => sliderRef.current?.slickGoTo(3)}
              >
                <CustomPaging
                  slideIndex={slideIndex}
                  index={3}
                  sliderRef={sliderRef}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhatWeDoSection;
